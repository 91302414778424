import { type QueryClient } from '@tanstack/react-query'
import { getIsOffline } from '@yms/common/components/Offline/getIsOffline'
import { type TableView } from '@yms/common/components/Table/Table.types'
import { tableSettings } from '@yms/common/components/Table/hooks/useTableSettingsQuery'
import { getTableQueryKey } from '@yms/common/components/Table/utils/getTableQueryKey'
import { getVisibleSorting } from '@yms/common/components/Table/utils/getVisibleSorting'

import { getUserContext } from '@mobile/auth/current-session/user'
import { queryClient } from '@mobile/App.queryClient'

import {
  type TablePrefetchConfig,
  type PrefetchConfig,
  type DetailTask,
  type TableTask,
  type TableViewTask,
  type Task
} from './PrefetchConfig'

export const prefetchWorker = new Worker(new URL('./prefetch.worker.ts', import.meta.url), {
  type: 'module'
})

const prefetchConfigs: PrefetchConfig[] = Object.values(
  import.meta.glob('../modules/**/pages/**/*.prefetch.ts', {
    eager: true,
    import: 'prefetchConfig'
  })
)

export const prefetch = {
  handle: 0,
  timeout: 0,
  refetchEnabled: true,
  queue: [] as Task[],
  queryKeys: new Set() as Set<string>,
  tryDelayedStart: () => {
    if (prefetch.timeout === 0) {
      prefetch.timeout = window.setTimeout(prefetch.start, 15_000) // NOSONAR
    }
  },
  taskHandler: async (deadline: IdleDeadline) => {
    while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && prefetch.queue.length > 0) {
      const task = prefetch.queue.shift()
      if (task) {
        try {
          // @ts-expect-error I cannot force it here to behave, chill TS
          await task.fn(...task.args)
        } catch {
          if (task.retries < 3) {
            task.retries += 1
            prefetch.queue.push(task)
          }
        }
      }
    }

    if (prefetch.queue.length > 0) {
      prefetch.handle = requestIdleCallback(prefetch.taskHandler)
    } else {
      prefetch.handle = 0
    }
  },
  setup: async () => {
    try {
      const { operatorId, handlerType, location } = await getUserContext(queryClient)

      for (const config of prefetchConfigs) {
        switch (config.type) {
          case 'table': {
            const task: TableTask = {
              type: 'table',
              args: [queryClient, config],
              retries: 0,
              fn: async (queryClient, { name }) => {
                if (!operatorId) throw new Error('Operator id is missing')

                const { views } = await queryClient.ensureQueryData({
                  queryKey: tableSettings.queryKey({ name, ownerId: operatorId }),
                  queryFn: () => tableSettings.queryFn({ name, ownerId: operatorId })
                })

                const viewQueue: TableViewTask[] = views.map(view => ({
                  type: 'view',
                  args: [queryClient, view, config],
                  retries: 0,
                  fn: async (
                    queryClient: QueryClient,
                    view: TableView,
                    table: TablePrefetchConfig
                  ) => {
                    const userContext = { location, handlerType }
                    const visibleSorting = getVisibleSorting(view)

                    const { rows } = await queryClient.fetchQuery({
                      queryKey: getTableQueryKey({
                        id: view.id,
                        userContext,
                        entity: name,
                        search: '',
                        sorting: visibleSorting,
                        filtering: view.filtering
                      }),
                      queryFn: async ({ queryKey, signal }) =>
                        table.fetcher({
                          signal,
                          queryKey,
                          search: '',
                          sorting: visibleSorting,
                          userContext,
                          filtering: view.filtering
                        })
                    })
                    prefetch.queryKeys.add(
                      JSON.stringify(
                        getTableQueryKey({
                          id: view.id,
                          userContext,
                          entity: name,
                          search: '',
                          sorting: visibleSorting,
                          filtering: view.filtering
                        })
                      )
                    )

                    queryClient.invalidateQueries({
                      queryKey: ['local-settings', 'table', { name, operatorId }]
                    })

                    const detailQueue: DetailTask[] = rows.map(row => ({
                      type: 'detail',
                      args: [queryClient, row],
                      fn: table.detailsFetcher,
                      retries: 0
                    }))

                    prefetch.queue.push(...detailQueue)
                  }
                }))

                prefetch.queue.push(...viewQueue)
              }
            }

            prefetch.queue.push(task)
            break
          }
          case 'query':
            prefetch.queue.push({
              type: 'query',
              args: [queryClient, prefetch.queue],
              fn: config.query,
              retries: 0
            })
            break
        }
      }
    } finally {
      if (!prefetch.refetchEnabled) {
        prefetch.stopRefetch()
      }
      prefetch.tryDelayedStart()
    }
  },
  start: async () => {
    prefetch.refetchEnabled = true
    const isOffline = getIsOffline()
    if (isOffline) {
      prefetch.tryDelayedStart()
      return
    }

    if (prefetch.queue.length === 0 && prefetch.handle === 0) {
      await prefetch.setup()
      prefetch.handle = requestIdleCallback(prefetch.taskHandler)
    }
  },
  stopRefetch: () => {
    if (prefetch.refetchEnabled) {
      prefetch.refetchEnabled = false
    }
    prefetch.queryKeys.forEach(queryKey => {
      queryClient.setQueryDefaults(JSON.parse(queryKey), {
        refetchInterval: false
      })
    })
  },
  stop: () => {
    prefetch.queue = []
    clearTimeout(prefetch.timeout)
    cancelIdleCallback(prefetch.handle)
    prefetch.timeout = 0
    prefetch.handle = 0
  }
}
