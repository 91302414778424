import {
  AdrClass,
  HandlerType,
  QueueMoveAfter,
  YardTaskStatus,
  SlotSize,
  SlotType,
  ShippingUnitKindType,
  YardZoneType,
  ShippingUnitStatus,
  ContentStatus,
  TransportStatus,
  NotificationDeliveryMethod,
  NotificationType,
  GhsClass,
  ProcessName
} from '@yms/api/client/v1/data-contracts'
import { SlotStatus } from '@yms/api/odata-client/ItgPltYmsApiModel'
import { MeansOfTransport, YardTaskType } from '@yms/api/client'

export const en = {
  goBack: 'Go back',
  loading: 'Loading...',
  delete: 'Delete',
  rename: 'Rename',
  cancel: 'Cancel',
  duplicate: 'Duplicate',
  more: 'More',
  loadingYardZonesNotPossible: 'Loading yard zones not possible.',
  loadingBlocksNotPossible: 'Loading blocks not possible.',
  loadingSlotsNotPossible: 'Loading slots not possible.',
  'depth:depth': 'Depth {depth}',
  'lastUpdated:datetime': 'Last updated: {datetime}',
  fetchingSlots: 'Fetching slots',
  fetchingBlocks: 'Fetching blocks',
  fetchingYardZones: 'Fetching yard zones',
  noSlotsFound: 'No slots found.',
  noBlocksFound: 'No blocks found.',
  noYardZonesFound: 'No yard zones found.',
  planned: 'Planned',
  alternative: 'Alternative',
  plannedUnloading: 'Planned (un)loading',
  unl: 'Unl',
  det: 'Det',
  clo: 'Clo',
  load: 'Load',
  'number:shippingUnits': '{number} shipping units',
  onChassis: 'On chassis',
  deactivated: 'Deactivated',
  takingLongerThanExpected: "It's taking longer than expected",
  internetConnectionPoor: "We're having trouble getting the data",
  'last:x:days': 'Last {x} days',
  lastMonth: 'Last month',
  viewCreated: 'View created',
  viewSaved: 'View changes saved',
  'view:x:deleted': 'View "{x}" deleted',
  viewRenamed: 'View renamed',
  saveChanges: 'Save changes',
  newView: 'New view',
  viewColumns: 'View columns',
  sortColumns: 'Sort columns',
  filters: 'Filters',
  pleaseSelectYourColumns: 'Please select your columns',
  saveAs: 'Save as',
  saveViewAs: 'Save view as',
  renameView: 'Rename view',
  viewAlreadyExists: 'View with this name already exists.',
  viewNameTooLong: 'View name is too long. (max 32 characters)',
  defaultViewIsProtected: 'Default is a protected name.',
  viewNameMustNotBeEmpty: 'View name must not be empty.',
  noDataAvailable: 'No data available',
  shippingUnitsAlternative: 'Shipping units (alternative)',
  unLoading: '(Un)loading',
  unLoadingFinished: '(Un)loading finished',
  yes: 'Yes',
  no: 'No',
  'markLoadingStatus.shippingUnitMustNotBeRejected': 'Shipping Unit must not be Rejected',
  'markLoadingStatus.onlyShippingUnitWithStatusInOrCheckedOut':
    'Only shipping units with status "In" or "Checked Out" can be (un)loaded. Check your selection and try again.',
  'label:invalidTimeFormat': '{label} has invalid format',
  availableForPickUp: 'Available for pick up',
  undoUnLoadingFinished: 'Undo (un)loading finished',
  'label:cannotBeInFuture': '{label} cannot be in the future.',
  'label:cannotBeInPast': '{label} cannot be in the past.',
  [MeansOfTransport.Truck]: 'Truck',
  [MeansOfTransport.Rail]: 'Rail',
  [MeansOfTransport.Barge]: 'Barge',
  [MeansOfTransport.None]: 'None',
  unsavedViewChanges: 'Unsaved view changes',
  unsavedViewChangesMessage:
    'You have unsaved changes in the view. Are you sure you want to leave?',
  [YardTaskType.Move]: 'Move',
  [YardTaskType.RepositionShippingUnit]: 'Reposition shipping unit',
  [YardTaskType.PressWaste]: 'Press waste',
  [YardTaskType.TurnContainerOnChassis]: 'Turn container on chassis',
  [YardTaskType.PlugInReeferContainer]: 'Plug in reefer container',
  [YardTaskType.WeighShippingUnit]: 'Weigh shipping unit',
  [YardTaskType.AddAirToTrailer]: 'Add air to trailer',
  [YardTaskType.OpenDoorsOfShippingUnit]: 'Open doors of shipping unit',
  [YardTaskType.HeatContainer]: 'Heat container',
  [SlotSize.FortyFeet]: '40 FT',
  [SlotSize.TwentyFeet]: '20 FT',
  [SlotType.ContainerStack]: 'Container Stack',
  [SlotType.FillingPipe]: 'Filling Pipe',
  [SlotType.Gate]: 'Gate',
  [SlotType.Parking]: 'Parking',
  [SlotType.Silo]: 'Silo',
  [ContentStatus.Full]: 'Full',
  [ContentStatus.Empty]: 'Empty',
  [TransportStatus.Accepted]: 'Accepted',
  [TransportStatus.Arrived]: 'Arrived',
  [TransportStatus.AvailableForPickUp]: 'Available for pick-up',
  [TransportStatus.BusyDelivery]: 'Busy delivery',
  [TransportStatus.BusyPickUp]: 'Busy pick-up',
  [TransportStatus.PlannedDelivery]: 'Planned delivery',
  [TransportStatus.PlannedPickUp]: 'Planned pick-up',
  [TransportStatus.OnHold]: 'On hold',
  [TransportStatus.Requested]: 'Requested',
  [AdrClass.Explosives]: 'Explosives',
  [AdrClass.FlammableGases]: 'Flammable Gases',
  [AdrClass.FlammableLiquids]: 'Flammable Liquids',
  [AdrClass.FlammableSolidsSelfReactive]: 'Flammable Solids',
  [AdrClass.OxidizingSubstances]: 'Oxidizing',
  [AdrClass.ToxicSubstances]: 'Toxic And Infectious',
  [AdrClass.Radioactive]: 'Radioactive',
  [AdrClass.Corrosives]: 'Corrosives',
  [AdrClass.Miscellaneous]: 'Miscellaneous',
  [AdrClass.NonFlammableNonToxicGases]: 'Non Flammable Non Toxic Gases',
  [AdrClass.ToxicGases]: 'Toxic Gases',
  [AdrClass.SubstancesSpontaneouslyCombustible]: 'Substances Spontaneously Combustible',
  [AdrClass.SubstancesEmitFlammableGasesInContactWithWater]:
    'Substances Emit Flammable Gases In Contact With Water',
  [AdrClass.OrganicPeroxides]: 'Organic Peroxides',
  [AdrClass.InfectiousSubstances]: 'Infectious Substances',
  [GhsClass.GHS01]: 'Explosives',
  [GhsClass.GHS02]: 'Flammables',
  [GhsClass.GHS03]: 'Oxidizers',
  [GhsClass.GHS04]: 'Compressed Gases',
  [GhsClass.GHS05]: 'Corrosives',
  [GhsClass.GHS06]: 'Acute Toxicity',
  [GhsClass.GHS07]: 'Harmful',
  [GhsClass.GHS08]: 'Health Hazard',
  [GhsClass.GHS09]: 'Environment Hazard',
  [ShippingUnitKindType.BulkContainer]: 'Bulk Container',
  [ShippingUnitKindType.BulkTrailer]: 'Bulk Trailer',
  [ShippingUnitKindType.Chassis]: 'Chassis',
  [ShippingUnitKindType.Hoppercar]: 'Hopper',
  [ShippingUnitKindType.Other]: 'Other',
  [ShippingUnitKindType.PackedContainer]: 'Packed Container',
  [ShippingUnitKindType.PalletTrailer]: 'Pallet Trailer',
  [ShippingUnitKindType.Railcar]: 'Railcar',
  [ShippingUnitKindType.TankContainer]: 'Tank Container',
  [ShippingUnitKindType.Vanbox]: 'Vanbox',
  [YardTaskStatus.Finished]: 'Finished',
  [YardTaskStatus.Cancelled]: 'Cancelled',
  [YardTaskStatus.Busy]: 'Busy',
  [YardTaskStatus.Planned]: 'Planned',
  [YardTaskStatus.Queued]: 'Queued',
  [QueueMoveAfter.DateTime]: 'Date Time',
  [QueueMoveAfter.PlannedOperationDate]: 'Planned Operation Date',
  [QueueMoveAfter.CheckIn]: 'Check In',
  [QueueMoveAfter.CheckOut]: 'Check Out',
  [ShippingUnitStatus.CheckedIn]: 'Checked In',
  [ShippingUnitStatus.CheckedOut]: 'Checked Out',
  [YardZoneType.SiloPack]: 'Silo Pack',
  [YardZoneType.Railyard]: 'Rail Yard',
  [HandlerType.ReachStacker]: 'Reach Stacker',
  [HandlerType.TerminalTruck]: 'Terminal Truck',
  // type
  [NotificationType.TransportArrivedForDropOff]: 'Transport arrived for drop-off',
  [NotificationType.InOutMoveQueued]: 'In/out move queued',
  [NotificationType.ShippingUnitShift]: 'Shipping unit shift',
  [NotificationType.ShippingUnitAtLoadingDock]: 'Shipping unit at loading dock',
  [NotificationType.TransportArrivedForPickup]: 'Transport arrived for pick-up',
  [NotificationType.ContentUpdate]: 'Content update',
  [NotificationType.MinMaxNumberOfShippingUnitsReached]:
    'Minimum/maximum number of shipping units reached',
  [NotificationDeliveryMethod.Email]: 'Email',
  [NotificationDeliveryMethod.SMS]: 'Phone number',
  [SlotStatus.Free]: 'Free',
  [SlotStatus.Occupied]: 'Occupied',
  [SlotStatus.PartiallyOccupied]: 'Partially occupied',
  history: 'History',
  shiftToOneLocation: 'Shift to one location',
  shiftToMultipleLocations: 'Shift to multiple locations',
  confirmAndNextSu: 'Confirm & next SU',
  finishedOn: 'Finished on',
  finishedBy: 'Finished by',
  cannotSearchEmptyString: 'Cannot search on an empty string.',
  'blockShippingUnit.setOut': 'set out',
  'blockShippingUnit.movement': 'movement',
  [ProcessName.RevertCheckIn]: 'Revert check in',
  [ProcessName.RevertCheckOut]: 'Revert check out',
  [ProcessName.CreateYardOrder]: 'Create yard visit',
  [ProcessName.UpdateYardOrder]: 'Update yard visit',
  [ProcessName.CancelYardOrder]: 'Cancel yard visit',
  [ProcessName.BlockYardOrder]: 'Block yard visit',
  [ProcessName.FinishLoadingUnloading]: 'Finish (un)loading',
  [ProcessName.RevertFinishLoadingUnloading]: 'Revert finish (un)loading',
  [ProcessName.SetAvailableForPickup]: 'Set available for pick-up',
  [ProcessName.SetUnavailableForPickup]: 'Set unavailable for pick-up',
  [ProcessName.Reuse]: 'Reuse',
  [ProcessName.UndoReuse]: 'Undo reuse',
  [ProcessName.Unblock]: 'Unblock',
  [ProcessName.Reject]: 'Reject',
  [ProcessName.Return]: 'Return',
  [ProcessName.CancelRejection]: 'Cancel rejection',
  [ProcessName.UpdateSeals]: 'Update seals',
  [ProcessName.UpdateToLocation]: 'Update to location',
  [ProcessName.UpdateExpectedLocation]: 'Update expected location',
  [ProcessName.UpdateOperatorsPlannedToLocation]: 'Update operators planned to location',
  [ProcessName.UpdatePlannedToLocation]: 'Update planned to location',
  [ProcessName.UpdateAlternativePlannedToLocation]: 'Update alternative planned to location',
  [ProcessName.UpdateSite]: 'Update site',
  [ProcessName.UpdateShippingUnitKind]: 'Update shipping unit kind',
  [ProcessName.CreateShiftMove]: 'Create "Shift" move',
  [ProcessName.FinishInMove]: 'Finish "In" move',
  [ProcessName.FinishShiftMove]: 'Finish "Shift" move',
  [ProcessName.FinishOutMove]: 'Finish "Out" move',
  [ProcessName.RequeueYardTask]: 'Requeue yard task',
  [ProcessName.ResetToPlanned]: 'Reset to planned',
  [ProcessName.CancelYardTask]: 'Cancel yard task',
  [ProcessName.FinishQuickShiftMove]: 'Finish quick "Shift" move',
  [ProcessName.ArdProcessVisit]: 'Ard process visit',
  [ProcessName.RulesEvaluation]: 'Rules evaluation',
  showCounterInNavigation: 'Show counter in navigation',
  or: 'or',
  is: 'is',
  isNot: 'is not',
  allows: 'allows',
  doNotAllow: 'do not allow',
  hasBeen: 'has been',
  hasNotBeen: 'has not been'
}
