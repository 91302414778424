export enum YardZoneType {
  ContainerStack = 'ContainerStack',
  Parking = 'Parking',
  SiloPack = 'SiloPack',
  Warehouse = 'Warehouse',
  Railyard = 'Railyard'
}

export enum ShippingUnitKindType {
  Other = 'Other',
  PackedContainer = 'PackedContainer',
  BulkTrailer = 'BulkTrailer',
  PalletTrailer = 'PalletTrailer',
  Chassis = 'Chassis',
  Railcar = 'Railcar',
  Hoppercar = 'Hoppercar',
  Vanbox = 'Vanbox',
  TankContainer = 'TankContainer',
  BulkContainer = 'BulkContainer'
}

export enum SlotType {
  ContainerStack = 'ContainerStack',
  Parking = 'Parking',
  FillingPipe = 'FillingPipe',
  Gate = 'Gate',
  Silo = 'Silo'
}

export enum HandlerType {
  ReachStacker = 'ReachStacker',
  TerminalTruck = 'TerminalTruck'
}

export enum SlotSize {
  TwentyFeet = 'TwentyFeet',
  FortyFeet = 'FortyFeet'
}

export enum SlotStatus {
  Free = 'Free',
  PartiallyOccupied = 'PartiallyOccupied',
  Occupied = 'Occupied'
}

export enum OperationalOrderType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Storage = 'Storage'
}

export interface YardTaskProjection {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardOrderId` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardOrderId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   */
  siteCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitNumber` |
   * | Type | `Edm.String` |
   */
  shippingUnitNumber: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitKind` |
   * | Type | `Edm.String` |
   */
  shippingUnitKind: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitStatus` |
   * | Type | `Edm.String` |
   */
  shippingUnitStatus: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `status` |
   * | Type | `Edm.String` |
   */
  status: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitContentStatus` |
   * | Type | `Edm.String` |
   */
  shippingUnitContentStatus: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   */
  type: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `moveType` |
   * | Type | `Edm.String` |
   */
  moveType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `handlerType` |
   * | Type | `Edm.String` |
   */
  handlerType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fromLocations` |
   * | Type | `Collection(Edm.String)` |
   */
  fromLocations: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `fromLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.ShippingUnitLocationProjection` |
   */
  fromLocationDetails: ShippingUnitLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `plannedToLocation` |
   * | Type | `Edm.String` |
   */
  plannedToLocation: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `plannedToLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.YardLocationProjection` |
   */
  plannedToLocationDetails: YardLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `alternativePlannedToLocation` |
   * | Type | `Edm.String` |
   */
  alternativePlannedToLocation: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `alternativePlannedToLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.YardLocationProjection` |
   */
  alternativePlannedToLocationDetails: YardLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operatorsPlannedToLocation` |
   * | Type | `Edm.String` |
   */
  operatorsPlannedToLocation: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operatorsPlannedToLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.YardLocationProjection` |
   */
  operatorsPlannedToLocationDetails: YardLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `actualToLocations` |
   * | Type | `Collection(Edm.String)` |
   */
  actualToLocations: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `actualToLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.ShippingUnitLocationProjection` |
   */
  actualToLocationDetails: ShippingUnitLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finalToLocation` |
   * | Type | `Edm.String` |
   */
  finalToLocation: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finalToLocationDetails` |
   * | Type | `ITG.PLT.YMS.Api.YardLocationProjection` |
   */
  finalToLocationDetails: YardLocationProjection | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isUrgent` |
   * | Type | `Edm.Boolean` |
   */
  isUrgent: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerCode` |
   * | Type | `Edm.String` |
   */
  customerCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `transportCompany` |
   * | Type | `Edm.String` |
   */
  transportCompany: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licensePlateTruck` |
   * | Type | `Edm.String` |
   */
  licensePlateTruck: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `licensePlateTrailer` |
   * | Type | `Edm.String` |
   */
  licensePlateTrailer: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `queuedOn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  queuedOn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `meansOfTransport` |
   * | Type | `Edm.String` |
   */
  meansOfTransport: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cargos` |
   * | Type | `Collection(ITG.PLT.YMS.Api.YardTaskCargoProjection)` |
   * | Nullable | `false` |
   */
  cargos: Array<YardTaskCargoProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finishBefore` |
   * | Type | `Edm.DateTimeOffset` |
   */
  finishBefore: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdBy` |
   * | Type | `Edm.String` |
   */
  createdBy: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  createdAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `queuedBy` |
   * | Type | `Edm.String` |
   */
  queuedBy: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finishedBy` |
   * | Type | `Edm.String` |
   */
  finishedBy: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finishedOn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  finishedOn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `finishingRemark` |
   * | Type | `Edm.String` |
   */
  finishingRemark: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `remark` |
   * | Type | `Edm.String` |
   */
  remark: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderIdentifier` |
   * | Type | `Edm.String` |
   */
  operationalOrderIdentifier: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderParentIdentifier` |
   * | Type | `Edm.String` |
   */
  operationalOrderParentIdentifier: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerReferences` |
   * | Type | `Edm.String` |
   */
  customerReferences: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerMainReference` |
   * | Type | `Edm.String` |
   */
  customerMainReference: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `queueErrors` |
   * | Type | `Collection(Edm.String)` |
   */
  queueErrors: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `hasQueueErrors` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  hasQueueErrors: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `parentOrderGroup` |
   * | Type | `Edm.String` |
   */
  parentOrderGroup: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `checkedInOutTime` |
   * | Type | `Edm.DateTimeOffset` |
   */
  checkedInOutTime: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `vessel` |
   * | Type | `Edm.String` |
   */
  vessel: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerKtn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerKtn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerShippingLine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerShippingLine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `closingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  closingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `closingOrDetentionDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  closingOrDetentionDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `loadingUnloadingPlannedAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  loadingUnloadingPlannedAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLoadingUnloadingFinished` |
   * | Type | `Edm.Boolean` |
   */
  isLoadingUnloadingFinished: boolean | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderType` |
   * | Type | `Edm.String` |
   */
  operationalOrderType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingLine` |
   * | Type | `Edm.String` |
   */
  shippingLine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `returnAddress` |
   * | Type | `Edm.String` |
   */
  returnAddress: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `alternativeReturnAddress` |
   * | Type | `Edm.String` |
   */
  alternativeReturnAddress: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isSwapAllowed` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isSwapAllowed: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isRequeueAllowed` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isRequeueAllowed: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitIsRejected` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  shippingUnitIsRejected: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitRejectReason` |
   * | Type | `Edm.String` |
   */
  shippingUnitRejectReason: string | null
}

export type YardTaskProjectionId = string | { id: string }

export interface YardOrderProjection {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitId` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  shippingUnitId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `status` |
   * | Type | `Edm.String` |
   */
  status: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `number` |
   * | Type | `Edm.String` |
   */
  number: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `contentStatus` |
   * | Type | `Edm.String` |
   */
  contentStatus: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `kind` |
   * | Type | `Edm.String` |
   */
  kind: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `meansOfTransportIn` |
   * | Type | `Edm.String` |
   */
  meansOfTransportIn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `meansOfTransportOut` |
   * | Type | `Edm.String` |
   */
  meansOfTransportOut: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `locations` |
   * | Type | `Collection(Edm.String)` |
   */
  locations: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `estimatedTimeArrival` |
   * | Type | `Edm.DateTimeOffset` |
   */
  estimatedTimeArrival: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `numberOfOpenTasks` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  numberOfOpenTasks: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   */
  siteCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingLine` |
   * | Type | `Edm.String` |
   */
  shippingLine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `vessel` |
   * | Type | `Edm.String` |
   */
  vessel: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `returnAddress` |
   * | Type | `Edm.String` |
   */
  returnAddress: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `alternativeReturnAddress` |
   * | Type | `Edm.String` |
   */
  alternativeReturnAddress: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `returnReference` |
   * | Type | `Edm.String` |
   */
  returnReference: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `alternativeReturnReference` |
   * | Type | `Edm.String` |
   */
  alternativeReturnReference: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerKtn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerKtn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerShippingLine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerShippingLine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `closingOrDetentionDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  closingOrDetentionDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `amsClosingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  amsClosingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `vgmClosingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  vgmClosingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `closingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  closingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `freeFullInFromDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  freeFullInFromDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerCode` |
   * | Type | `Edm.String` |
   */
  customerCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerMainReference` |
   * | Type | `Edm.String` |
   */
  customerMainReference: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerReferences` |
   * | Type | `Collection(Edm.String)` |
   */
  customerReferences: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderId` |
   * | Type | `Edm.String` |
   */
  operationalOrderId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderParentId` |
   * | Type | `Edm.String` |
   */
  operationalOrderParentId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderType` |
   * | Type | `Edm.String` |
   */
  operationalOrderType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `loadingUnloadingPlannedAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  loadingUnloadingPlannedAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `cargos` |
   * | Type | `Collection(ITG.PLT.YMS.Api.YardOrderCargoProjection)` |
   * | Nullable | `false` |
   */
  cargos: Array<YardOrderCargoProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `Edm.String` |
   */
  yardZone: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `block` |
   * | Type | `Edm.String` |
   */
  block: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slots` |
   * | Type | `Collection(Edm.String)` |
   */
  slots: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `driverWaits` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  driverWaits: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isAvailableForPickup` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isAvailableForPickup: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isRejected` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isRejected: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rejectReason` |
   * | Type | `Edm.String` |
   */
  rejectReason: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isOnChassis` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isOnChassis: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLiftOnOff` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLiftOnOff: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `parentOrderGroup` |
   * | Type | `Edm.String` |
   */
  parentOrderGroup: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isReused` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isReused: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isBlocked` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isBlocked: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `actionBlocks` |
   * | Type | `Collection(ITG.PLT.YMS.Api.ActionBlockProjection)` |
   * | Nullable | `false` |
   */
  actionBlocks: Array<ActionBlockProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `transportStatus` |
   * | Type | `Edm.String` |
   */
  transportStatus: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `inAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  inAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `incomingSeals` |
   * | Type | `Collection(Edm.String)` |
   */
  incomingSeals: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `outgoingSeals` |
   * | Type | `Collection(Edm.String)` |
   */
  outgoingSeals: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `hasIncomingSeals` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  hasIncomingSeals: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `hasOutgoingSeals` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  hasOutgoingSeals: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrders` |
   * | Type | `Collection(ITG.PLT.YMS.Api.OperationalOrderProjection)` |
   */
  operationalOrders?: Array<OperationalOrderProjection>
}

export type YardOrderProjectionId = string | { id: string }

export interface NotificationSubscriptionProjection {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   */
  name: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `notificationType` |
   * | Type | `Edm.String` |
   */
  notificationType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `site` |
   * | Type | `Edm.String` |
   */
  site: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `Edm.String` |
   */
  yardZone: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `block` |
   * | Type | `Edm.String` |
   */
  block: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `notificationRecipients` |
   * | Type | `Collection(ITG.PLT.YMS.Api.NotificationRecipientProjection)` |
   * | Nullable | `false` |
   */
  notificationRecipients: Array<NotificationRecipientProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `notificationConditions` |
   * | Type | `Collection(ITG.PLT.YMS.Api.NotificationConditionProjection)` |
   * | Nullable | `false` |
   */
  notificationConditions: Array<NotificationConditionProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  createdAt: string | null
}

export type NotificationSubscriptionProjectionId = string | { id: string }

export interface RuleProjection {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  type: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `triggers` |
   * | Type | `Collection(ITG.PLT.YMS.Api.TriggerProjection)` |
   */
  triggers: Array<TriggerProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteConditions` |
   * | Type | `Collection(ITG.PLT.YMS.Api.RuleConditionProjection)` |
   */
  siteConditions: Array<RuleConditionProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitLocationConditions` |
   * | Type | `Collection(ITG.PLT.YMS.Api.RuleConditionProjection)` |
   */
  shippingUnitLocationConditions: Array<RuleConditionProjection>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `actions` |
   * | Type | `Collection(ITG.PLT.YMS.Api.ActionProjection)` |
   */
  actions: Array<ActionProjection>
}

export type RuleProjectionId = string | { id: string }

export interface ODataSiteDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shortName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  shortName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `description` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  description: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `longitude` |
   * | Type | `Edm.Double` |
   */
  longitude: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `latitude` |
   * | Type | `Edm.Double` |
   */
  latitude: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `address` |
   * | Type | `ITG.PLT.YMS.Api.ODataAddressDto` |
   */
  address: ODataAddressDto | null
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
}

export type ODataSiteDtoId = string | { id: string }

export interface ODataYardZoneDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneType` |
   * | Type | `ITG.PLT.YMS.Api.YardZoneType` |
   * | Nullable | `false` |
   */
  yardZoneType: YardZoneType
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLiftOnOff` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLiftOnOff: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  siteId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blocksCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  blocksCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
}

export type ODataYardZoneDtoId = string | { id: string }

export interface ODataShippingUnitKindDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `description` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  description: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lengthInFeet` |
   * | Type | `Edm.Int32` |
   */
  lengthInFeet: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `ITG.PLT.YMS.Api.ShippingUnitKindType` |
   * | Nullable | `false` |
   */
  type: ShippingUnitKindType
}

export type ODataShippingUnitKindDtoId = string | { id: string }

export interface ODataBlockDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `prefix` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  prefix: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotType` |
   * | Type | `ITG.PLT.YMS.Api.SlotType` |
   */
  slotType: SlotType | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  slotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardZoneId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardZoneName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLiftOnOff` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLiftOnOff: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isDefault` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isDefault: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `ITG.PLT.YMS.Api.ODataYardZoneDto` |
   */
  yardZone?: ODataYardZoneDto | null
}

export type ODataBlockDtoId = string | { id: string }

export interface ODataHandlerDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `assetNumber` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  assetNumber: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `ITG.PLT.YMS.Api.HandlerType` |
   * | Nullable | `false` |
   */
  type: HandlerType
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
}

export type ODataHandlerDtoId = string | { id: string }

export interface ODataOperatorDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `firstName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  firstName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lastName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  lastName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `username` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  username: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCodes` |
   * | Type | `Collection(Edm.String)` |
   */
  siteCodes: Array<string>
}

export type ODataOperatorDtoId = string | { id: string }

export interface ODataSlotDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `row` |
   * | Type | `Edm.Int32` |
   */
  row: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `depth` |
   * | Type | `Edm.Int32` |
   */
  depth: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `level` |
   * | Type | `Edm.Int32` |
   */
  level: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLiftOnOff` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLiftOnOff: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLoadingDock` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLoadingDock: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isDefault` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isDefault: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotType` |
   * | Type | `ITG.PLT.YMS.Api.SlotType` |
   * | Nullable | `false` |
   */
  slotType: SlotType
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotSize` |
   * | Type | `ITG.PLT.YMS.Api.SlotSize` |
   */
  slotSize: SlotSize | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  blockId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  blockName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardZoneId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardZoneName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
}

export type ODataSlotDtoId = string | { id: string }

export interface ODataCustomerDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
}

export type ODataCustomerDtoId = string | { id: string }

export interface ODataShippingLineDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
}

export type ODataShippingLineDtoId = string | { id: string }

export interface ODataTransportCompanyDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
}

export type ODataTransportCompanyDtoId = string | { id: string }

export interface ODataYardVisitExternalIdDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `externalIdentifier` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  externalIdentifier: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardVisitId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdAt` |
   * | Type | `Edm.DateTimeOffset` |
   * | Nullable | `false` |
   */
  createdAt: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdBy` |
   * | Type | `Edm.String` |
   */
  createdBy: string | null
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lastModifiedAt` |
   * | Type | `Edm.DateTimeOffset` |
   * | Nullable | `false` |
   */
  lastModifiedAt: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lastModifiedBy` |
   * | Type | `Edm.String` |
   */
  lastModifiedBy: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rowVersion` |
   * | Type | `Edm.Binary` |
   * | Nullable | `false` |
   */
  rowVersion: string
}

export type ODataYardVisitExternalIdDtoId = string | { id: string }

export interface ODataYardTaskFinishContextDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `ITG.PLT.YMS.Api.Type` |
   * | Nullable | `false` |
   */
  type: Type
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `taskId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  taskId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardVisitId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `swappedYardVisitId` |
   * | Type | `Edm.Guid` |
   */
  swappedYardVisitId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdAt` |
   * | Type | `Edm.DateTimeOffset` |
   * | Nullable | `false` |
   */
  createdAt: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `createdBy` |
   * | Type | `Edm.String` |
   */
  createdBy: string | null
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lastModifiedAt` |
   * | Type | `Edm.DateTimeOffset` |
   * | Nullable | `false` |
   */
  lastModifiedAt: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lastModifiedBy` |
   * | Type | `Edm.String` |
   */
  lastModifiedBy: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `rowVersion` |
   * | Type | `Edm.Binary` |
   * | Nullable | `false` |
   */
  rowVersion: string
}

export type ODataYardTaskFinishContextDtoId = string | { id: string }

export interface ODataOperationalSiteDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `code` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  code: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `freeSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  freeSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `occupiedSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  occupiedSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `totalSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  totalSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  yardVisitsCount: number
}

export type ODataOperationalSiteDtoId = string | { id: string }

export interface ODataOperationalYardZoneDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `freeSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  freeSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `occupiedSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  occupiedSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `totalSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  totalSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  yardVisitsCount: number
}

export type ODataOperationalYardZoneDtoId = string | { id: string }

export interface ODataOperationalBlockDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardZoneId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardZoneName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `totalSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  totalSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `freeSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  freeSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `occupiedSlotsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  occupiedSlotsCount: number
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitsCount` |
   * | Type | `Edm.Int32` |
   * | Nullable | `false` |
   */
  yardVisitsCount: number
}

export type ODataOperationalBlockDtoId = string | { id: string }

export interface ODataOperationalSlotDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `siteCode` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  siteCode: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `name` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  name: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `row` |
   * | Type | `Edm.Int32` |
   */
  row: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `depth` |
   * | Type | `Edm.Int32` |
   */
  depth: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `level` |
   * | Type | `Edm.Int32` |
   */
  level: number | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isLiftOnOff` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isLiftOnOff: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotType` |
   * | Type | `ITG.PLT.YMS.Api.SlotType` |
   * | Nullable | `false` |
   */
  slotType: SlotType
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotSize` |
   * | Type | `ITG.PLT.YMS.Api.SlotSize` |
   */
  slotSize: SlotSize | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slotStatus` |
   * | Type | `ITG.PLT.YMS.Api.SlotStatus` |
   * | Nullable | `false` |
   */
  slotStatus: SlotStatus
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  blockId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  blockName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardZoneId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZoneName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardZoneName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisits` |
   * | Type | `Collection(ITG.PLT.YMS.Api.ODataSlotYardVisitDto)` |
   */
  yardVisits: Array<ODataSlotYardVisitDto>
}

export type ODataOperationalSlotDtoId = string | { id: string }

export interface ODataSwapEligibleContainerDto {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardOrderId` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  yardOrderId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `number` |
   * | Type | `Edm.String` |
   */
  number: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `locations` |
   * | Type | `Collection(Edm.String)` |
   */
  locations: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `locationDetails` |
   * | Type | `ITG.PLT.YMS.Api.ODataShippingUnitLocationDto` |
   */
  locationDetails: ODataShippingUnitLocationDto | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerKtn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerKtn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `realClosingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  realClosingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `kind` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  kind: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `adrClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  adrClasses: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ghsClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  ghsClasses: Array<string>
}

export type ODataSwapEligibleContainerDtoId = string | { yardOrderId: string }

export interface OperationalOrderProjection {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  id: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerMainReference` |
   * | Type | `Edm.String` |
   */
  customerMainReference: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customerReferences` |
   * | Type | `Collection(Edm.String)` |
   */
  customerReferences: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderId` |
   * | Type | `Edm.String` |
   */
  operationalOrderId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `operationalOrderParentId` |
   * | Type | `Edm.String` |
   */
  operationalOrderParentId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `loadedUnloadedAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  loadedUnloadedAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isActive` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isActive: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `owner` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  owner: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockedProcesses` |
   * | Type | `Collection(ITG.PLT.YMS.Api.BlockedProcessProjection)` |
   */
  blockedProcesses: Array<BlockedProcessProjection>
}

export type OperationalOrderProjectionId = string | { id: string }

export interface ShippingUnitLocationProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `Edm.String` |
   */
  yardZone: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `block` |
   * | Type | `Edm.String` |
   */
  block: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slots` |
   * | Type | `Collection(Edm.String)` |
   */
  slots: Array<string>
}

export interface YardLocationProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `Edm.String` |
   */
  yardZone: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `block` |
   * | Type | `Edm.String` |
   */
  block: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slot` |
   * | Type | `Edm.String` |
   */
  slot: string | null
}

export interface YardTaskCargoProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `article` |
   * | Type | `Edm.String` |
   */
  article: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `product` |
   * | Type | `Edm.String` |
   */
  product: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lotBatch` |
   * | Type | `Edm.String` |
   */
  lotBatch: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `configuration` |
   * | Type | `Edm.String` |
   */
  configuration: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `warehouseCode` |
   * | Type | `Edm.String` |
   */
  warehouseCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `gateCode` |
   * | Type | `Edm.String` |
   */
  gateCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `adrClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  adrClasses: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ghsClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  ghsClasses: Array<string>
}

export interface YardOrderCargoProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `article` |
   * | Type | `Edm.String` |
   */
  article: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `product` |
   * | Type | `Edm.String` |
   */
  product: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `lotBatch` |
   * | Type | `Edm.String` |
   */
  lotBatch: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `configuration` |
   * | Type | `Edm.String` |
   */
  configuration: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `warehouseCode` |
   * | Type | `Edm.String` |
   */
  warehouseCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `gateCode` |
   * | Type | `Edm.String` |
   */
  gateCode: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `adrClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  adrClasses: Array<string>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ghsClasses` |
   * | Type | `Collection(Edm.String)` |
   */
  ghsClasses: Array<string>
}

export interface BlockedProcessProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `processName` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  processName: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `conditions` |
   * | Type | `Collection(ITG.PLT.YMS.Api.ConditionProjection)` |
   */
  conditions: Array<ConditionProjection>
}

export interface ConditionProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  type: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `values` |
   * | Type | `Collection(Edm.String)` |
   */
  values: Array<string>
}

export interface ActionBlockProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockMovements` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  blockMovements: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockCheckOut` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  blockCheckOut: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `reason` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  reason: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `blockedBy` |
   * | Type | `Edm.String` |
   */
  blockedBy: string | null
}

export interface NotificationRecipientProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `recipientType` |
   * | Type | `Edm.String` |
   */
  recipientType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `value` |
   * | Type | `Edm.String` |
   */
  value: string | null
}

export interface NotificationConditionProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `conditionType` |
   * | Type | `Edm.String` |
   */
  conditionType: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `values` |
   * | Type | `Collection(Edm.String)` |
   */
  values: Array<string>
}

export interface TriggerProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  type: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `value` |
   * | Type | `Edm.String` |
   */
  value: string | null
}

export interface RuleConditionProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  type: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `values` |
   * | Type | `Collection(Edm.String)` |
   */
  values: Array<string>
}

export interface ActionProjection {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `type` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  type: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `value` |
   * | Type | `Edm.String` |
   */
  value: string | null
}

export interface ODataAddressDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `streetAndNumber` |
   * | Type | `Edm.String` |
   */
  streetAndNumber: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `box` |
   * | Type | `Edm.String` |
   */
  box: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `building` |
   * | Type | `Edm.String` |
   */
  building: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `city` |
   * | Type | `Edm.String` |
   */
  city: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `zip` |
   * | Type | `Edm.String` |
   */
  zip: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `country` |
   * | Type | `Edm.String` |
   */
  country: string | null
}

export interface MemberInfo {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `customAttributes` |
   * | Type | `Collection(ITG.PLT.YMS.Api.CustomAttributeData)` |
   * | Nullable | `false` |
   */
  customAttributes: Array<CustomAttributeData>
}

export interface Type extends MemberInfo {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `genericTypeArguments` |
   * | Type | `Collection(ITG.PLT.YMS.Api.Type)` |
   * | Nullable | `false` |
   */
  genericTypeArguments: Array<Type>
}

export interface CustomAttributeData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `constructorArguments` |
   * | Type | `Collection(ITG.PLT.YMS.Api.CustomAttributeTypedArgument)` |
   */
  constructorArguments: Array<CustomAttributeTypedArgument>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `namedArguments` |
   * | Type | `Collection(ITG.PLT.YMS.Api.CustomAttributeNamedArgument)` |
   */
  namedArguments: Array<CustomAttributeNamedArgument>
}

export interface CustomAttributeTypedArgument {}

export interface CustomAttributeNamedArgument {}

export interface TypeInfo extends Type {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `genericTypeParameters` |
   * | Type | `Collection(ITG.PLT.YMS.Api.Type)` |
   * | Nullable | `false` |
   */
  genericTypeParameters: Array<Type>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredConstructors` |
   * | Type | `Collection(ITG.PLT.YMS.Api.ConstructorInfo)` |
   */
  declaredConstructors: Array<ConstructorInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredEvents` |
   * | Type | `Collection(ITG.PLT.YMS.Api.EventInfo)` |
   */
  declaredEvents: Array<EventInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredFields` |
   * | Type | `Collection(ITG.PLT.YMS.Api.FieldInfo)` |
   */
  declaredFields: Array<FieldInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredMembers` |
   * | Type | `Collection(ITG.PLT.YMS.Api.MemberInfo)` |
   */
  declaredMembers: Array<MemberInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredMethods` |
   * | Type | `Collection(ITG.PLT.YMS.Api.MethodInfo)` |
   */
  declaredMethods: Array<MethodInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredNestedTypes` |
   * | Type | `Collection(ITG.PLT.YMS.Api.TypeInfo)` |
   * | Nullable | `false` |
   */
  declaredNestedTypes: Array<TypeInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `declaredProperties` |
   * | Type | `Collection(ITG.PLT.YMS.Api.PropertyInfo)` |
   */
  declaredProperties: Array<PropertyInfo>
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `implementedInterfaces` |
   * | Type | `Collection(ITG.PLT.YMS.Api.Type)` |
   * | Nullable | `false` |
   */
  implementedInterfaces: Array<Type>
}

export interface GenericTypeParameterBuilder extends TypeInfo {}

export interface MethodBase extends MemberInfo {}

export interface ConstructorInfo extends MethodBase {}

export interface ConstructorBuilder extends ConstructorInfo {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `initLocals` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  initLocals: boolean
}

export interface EventInfo extends MemberInfo {}

export interface ComAwareEventInfo extends EventInfo {}

export interface FieldInfo extends MemberInfo {}

export interface FieldBuilder extends FieldInfo {}

export interface PropertyInfo extends MemberInfo {}

export interface PropertyBuilder extends PropertyInfo {}

export interface MethodInfo extends MethodBase {}

export interface MethodBuilder extends MethodInfo {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `initLocals` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  initLocals: boolean
}

export interface DynamicMethod extends MethodInfo {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `initLocals` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  initLocals: boolean
}

export interface EnumBuilder extends TypeInfo {}

export interface TypeBuilder extends TypeInfo {}

export interface TypeDelegator extends TypeInfo {}

export interface ODataSlotYardVisitDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardVisitId` |
   * | Type | `Edm.Guid` |
   * | Nullable | `false` |
   */
  yardVisitId: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `loadingUnloadingPlannedAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  loadingUnloadingPlannedAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `loadedUnloadedAt` |
   * | Type | `Edm.DateTimeOffset` |
   */
  loadedUnloadedAt: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerKtn` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerKtn: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `detentionDateCustomerShippingLine` |
   * | Type | `Edm.DateTimeOffset` |
   */
  detentionDateCustomerShippingLine: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `closingDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  closingDate: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitNumber` |
   * | Type | `Edm.String` |
   */
  shippingUnitNumber: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `shippingUnitKind` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  shippingUnitKind: string
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `orderId` |
   * | Type | `Edm.String` |
   */
  orderId: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `pickUpAddress` |
   * | Type | `Edm.String` |
   */
  pickUpAddress: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `isOnChassis` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  isOnChassis: boolean
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `orderType` |
   * | Type | `ITG.PLT.YMS.Api.OperationalOrderType` |
   */
  orderType: OperationalOrderType | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `hasQueuedMoves` |
   * | Type | `Edm.Boolean` |
   * | Nullable | `false` |
   */
  hasQueuedMoves: boolean
}

export interface ODataShippingUnitLocationDto {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `yardZone` |
   * | Type | `Edm.String` |
   */
  yardZone: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `block` |
   * | Type | `Edm.String` |
   */
  block: string | null
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `slots` |
   * | Type | `Collection(Edm.String)` |
   */
  slots: Array<string>
}
